import type { ComponentType } from "react"
import { useEffect } from "react"
import { useAnimate } from "framer-motion"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

const useStore = createStore({})

enum ProductTypes {
    mediation = 50,
    diyPaperwork = 63,
    weFileForYou = 67,
    assistedDivorce = 92,
    fullyGuidedDivorce = 93,
}

const fetchStatuses = {}

const fetchPriceFromAPI = async (params) => {
    const fetchKey = JSON.stringify(params)
    if (fetchStatuses[fetchKey]) {
        return null
    }
    fetchStatuses[fetchKey] = true
    const baseUrl = window.location.hostname.includes("divorce.com")
        ? "https://divorce.com"
        : "https://staging.completecase.com:8190"
    const url = new URL("/api/v2/product-prices/", baseUrl)
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url.toString())
    const data = await response.json()
    fetchStatuses[fetchKey] = false
    return data
}

const getPriceString = (price: number, affirm: boolean) =>
    affirm
        ? `4 payments of $${Number(price / 4).toLocaleString("en-US")}/mo`
        : `$${Number(price).toLocaleString("en-US")}`

const withRealPriceFactory =
    (Component, productType: ProductTypes, affirm: boolean) => (props) => {
        const [store, setStore] = useStore()
        const [scope, animate] = useAnimate()
        const price = store[productType] || 0

        useEffect(() => {
            const getAndSetPrice = async () => {
                const params = { product_types: productType }
                const data = await fetchPriceFromAPI(params)
                data && setStore({ ...store, [productType]: data[productType] })
            }
            !price && getAndSetPrice()
        }, [])
        useEffect(() => {
            price && animate(scope.current, { opacity: 1 }, { duration: 0.1 })
        }, [price])

        const newPrors = price
            ? { ...props, text: getPriceString(price, affirm) }
            : { ...props, initial: { opacity: 0 } }

        return <Component {...newPrors} ref={scope} />
    }

export function withMediationRealPrice(Component): ComponentType {
    return withRealPriceFactory(Component, ProductTypes.mediation, false)
}

export function withDiyPaperworkRealPrice(Component): ComponentType {
    return withRealPriceFactory(Component, ProductTypes.diyPaperwork, false)
}

export function withWeFileForYouRealPrice(Component): ComponentType {
    return withRealPriceFactory(Component, ProductTypes.weFileForYou, false)
}

export function withAssistedDivorceRealPrice(Component): ComponentType {
    return withRealPriceFactory(Component, ProductTypes.assistedDivorce, false)
}

export function withFullyGuidedDivorceRealPrice(Component): ComponentType {
    return withRealPriceFactory(
        Component,
        ProductTypes.fullyGuidedDivorce,
        false
    )
}

export function withDiyPaperworkAffirmRealPrice(Component): ComponentType {
    return withRealPriceFactory(Component, ProductTypes.diyPaperwork, true)
}

export function withWeFileForYouAffirmRealPrice(Component): ComponentType {
    return withRealPriceFactory(Component, ProductTypes.weFileForYou, true)
}

export function withAssistedDivorceAffirmRealPrice(Component): ComponentType {
    return withRealPriceFactory(Component, ProductTypes.assistedDivorce, true)
}

export function withFullyGuidedDivorceAffirmRealPrice(
    Component
): ComponentType {
    return withRealPriceFactory(
        Component,
        ProductTypes.fullyGuidedDivorce,
        true
    )
}
